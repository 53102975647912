import { useState } from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import toast, { Toaster } from 'react-hot-toast'

import { getCurrentUser, getFirebase } from 'utils/firebase'
import { useRouter } from 'next/router'

const firebase = getFirebase()

const API_URL = 'https://logs.simplesat.io/api'

const CodeBlock = styled.pre`
  background: #292929;
  color: #fafafa;
  padding: 1em 0 1em 1em;
`

export default function Home() {
  return <LogTable />
}

const exampleLogs = [
  {
    action: 'save_answer_choice',
    actor: 'cus_7777777',
    company: 'com_9999',
    created: '2020-08-05T15:59:47.944Z',
    data: {
      nextValue: '18886',
      previousValue: 'NULL',
    },
    pk: 'com_9999|cus_7777777',
    sk: '2020-08-05T15:59:47.944Z|ses_5555555|save_answer_choice|123123',
    target: 'ses_5555555',
  },
]

const ACTIONS = [
  '',
  'archive_survey',
  'duplicate_survey',
  'edit_survey_publish_email_page',
  'toggle_follow_up_email_survey',
  'edit_survey_publish_delivery_page',
  'edit_survey_publish_delivery_page',
  'edit_survey_access',
  'edit_user_role_and_survey_access',
  'delete_user',
  'edit_survey_builder',
  'edit_survey_configuration',
  'authenticate_connectwise_credential',
  'revoke_connectwise_credential',
  'activate_connectwise_customer_sync_integration',
  'deactivate_connectwise_customer_sync_integration',
  'authenticate_zendesk_credential',
  'revoke_zendesk_credential',
  'activate_import_zendesk_satisfaction_rating_integration',
  'deactivate_import_zendesk_satisfaction_rating_integration',
  'authenticate_autotask_credential',
  'revoke_autotask_credential',
  'activate_autotask_customer_sync_integration',
  'deactivate_autotask_customer_sync_integration',
  'activate_intercom_customer_sync_integration',
  'deactivate_intercom_customer_sync_integration',
  'authenticate_intercom_credential',
  'revoke_intercom_credential',
  // customer
  'add_customer',
  'edit_customer',
  'delete_customer',
  'subscribe_email',
  'unsubscrive_email',
  'trigger_import_csv',
  'export_customer',
]

function searchData(searchBy, searchValue, actionValue) {
  return getCurrentUser()
    .getIdToken()
    .then((idToken) => {
      const axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      if (searchBy === 'target') {
        return axiosInstance.get(`${API_URL}/logs/by-target/`, {
          params: { target: searchValue },
        })
      }

      if (actionValue !== '') {
        return axiosInstance.get(`${API_URL}/logs/by-company/action`, {
          params: { company: searchValue, action: actionValue },
        })
      }

      return axiosInstance.get(`${API_URL}/logs/by-company`, {
        params: { company: searchValue },
      })
    })
}

function getPlaceholder(type) {
  const mapTypeToPlaceholder = {
    company: 'com_1086',
    action: 'add_tag',
    target: 'ses_3669547',
  }
  return mapTypeToPlaceholder[type]
}

function LogTable() {
  const [searchValue, setSearchValue] = useState('')
  const [actionValue, setActionValue] = useState('')
  const [count, setCount] = useState(1)
  const [searchBy, setSearchBy] = useState('company')
  const [logs, setLogs] = useState(exampleLogs)

  function searchLog(e) {
    e.preventDefault()

    if (searchValue == '') {
      return toast.error('Please input the value.')
    }

    searchData(searchBy, searchValue, actionValue)
      .then((response) => {
        setLogs(response.data.Items)
        setCount(response.data.Items.length)
      })
      .catch(() => {
        toast.error('Oops! Something went wrong.')
      })
  }

  function resetSearchText() {
    setSearchValue('')
    setActionValue('')
  }

  return (
    <div>
      <div className="flex justify-end pr-5 pt-4">
        <LogOutButton />
      </div>
      <div className="flex flex-col justify-center items-center w-2/3 mx-auto">
        <div className="w-full relative text-gray-600 mt-4">
          <form onSubmit={searchLog}>
            <div className="bg-white rounded flex items-center w-full p-3 shadow-sm border border-gray-200">
              <button className="outline-none focus:outline-none">
                <svg
                  className=" w-5 text-gray-600 h-5 cursor-pointer"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
              <input
                type="search"
                className="w-full pl-4 text-sm outline-none focus:outline-none bg-transparent"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder={getPlaceholder(searchBy)}
              />
              <div className="select">
                <select
                  className="text-sm outline-none focus:outline-none bg-transparent"
                  onChange={(e) => setSearchBy(e.target.value)}
                >
                  <option value="company" selected={searchBy === 'company'}>
                    Company
                  </option>
                  <option value="target" selected={searchBy === 'target'}>
                    Target
                  </option>
                </select>
              </div>
            </div>
            {searchBy === 'company' && (
              <div className="flex mt-2">
                <span className="text-sm border border-2 rounded-l px-4 py-2 bg-gray-300 whitespace-nowrap">
                  With filter action:
                </span>
                <select
                  className="text-sm outline-none focus:outline-none bg-transparent border border-2 rounded-r px-4 py-2 w-full"
                  onChange={(e) => setActionValue(e.target.value)}
                >
                  {ACTIONS.map((action) => {
                    return (
                      <option value={action} selected={actionValue === action}>
                        {action}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            <div className="py-2 text-right">
              <button type="submit" className="mr-2 btn-primary w-28">
                Search
              </button>
              <button
                type="button"
                className="btn-secondary w-28"
                onClick={() => resetSearchText()}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
        <hr className="my-5 w-full bg-green" />
        <div className="w-full">
          <h2 className="text-right font-bold text-lg leading-none">Count: {count}</h2>
          {logs.map((log) => {
            const jsonTextLog = JSON.stringify(log, undefined, 4)
            return (
              <div className="my-5">
                <CodeBlock>{jsonTextLog}</CodeBlock>
              </div>
            )
          })}
        </div>
        <Toaster />
      </div>
    </div>
  )
}

function LogOutButton() {
  const router = useRouter()

  function handleLogOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        router.push('/login/')
      })
  }

  return (
    <button
      className="inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-black uppercase transition bg-gray-100 rounded shadow ripple hover:shadow-lg hover:bg-gray-200 focus:outline-none waves-effect border-gray-300"
      onClick={handleLogOut}
    >
      Log out
    </button>
  )
}
